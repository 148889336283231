tr.item {
    --bs-table-bg: #b9faf0;
}
tr.item.active {
    --bs-table-bg: #fab9b9;
    font-size: 1.5em;
}

tr.item.packed {
    --bs-table-bg: #80f562;
}
tr.item.active td.more-than-one {
    font-weight: bold;
}
div.scale.unknown {
    background-color: #f14646;
}
div.scale.zeroed {
    background-color: #acf4f6;
}
div.scale.not-zeroed {
    background-color: #f5a563;
}
div.scale.unstable {
    background-color: #f5dc5f;
}
div.scale.valid-weight {
    background-color: #a5f55f;
}
div.scale.over-max-weight {
    background-color: #f14646;
}
div.scale.offline {
    background-color: #f14646;
}
span.scale-value {
    font-size: 2em;
    font-weight: bold;
}
span.packing-qty {
    font-size: 3em;
    font-weight: bold;
}
span.packing-sku {
    font-size: 2em;
    font-weight: bold;
}
div.process {
    text-align: center !important;
    border: 2px solid black;
    padding: 0;
    margin: 0;
    opacity: 30%;
}
div.process.active {
    opacity: 100%;
    background-color: #acf4f6;
}
div.process.completed {
    opacity: 100%;
    background-color: #a5f55f;
}
div.process-inner {
    padding: 1em;
    margin: 0;
    height: 100%;
}
span.ship-carrier {
    font-size: 1.5em;
    font-weight: bold;
}
span.ship-service {
    font-size: 1.2em;
    font-weight: bold;
}span.ship-tracking{
     font-size: 1.1em;
     font-weight: bold;
 }
#weighing-spinner {
    display: none;
}
#create-label-spinner {
    display: none;
}
#shipment-spinner {
    display: none;
}
#shipment-spinner2 {
    display: none;
}
.buy-print-spinner {
    display: none;
}
.spinner-border {
    position: absolute;
    width: 5em;
    height: 5em;
    top: 0;
    left: 0;
}
.overlay-spinner {
    position: relative;
}
.table-responsive {
    max-height:200px;
}
div.packing {
    height: 148px;
}
div.create-label {
    height: 148px;
}

div.items-table {
    height: 160px;
}

div#shipping-labels {
    height: 550px;
}
