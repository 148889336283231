@import '~bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import 'dashboard.css';
@import 'packing.css';

body {
    background-color: lightgray;
}

code {
    color: black;
}
